<template>
  <div>
    <HeaderBar />
    <div class="d-flex">
      <div class="w-100 default-layout_router">
        <slot />
      </div>
    </div>
  </div>
</template>
